/* You can add global styles to this file, and also import other style files */
// .searchBar{display:inline-block;
//     width:80%}

// .normalBtn{
//     margin-left: 16px;
//     margin-right: 16px;
// }

// h2{
//     font-weight: bold;
//     margin-bottom: 12px;
//   }
  
//  .detailTtl{
//     color:#462CE6;
//     font-size: 13px;
//     margin-bottom: 20px;
// }
// .header-search{
//     float: left;
//     width: 200px;
//     margin-right:20px;
// }

// .detailText{
//     margin-bottom:20px; 
//     overflow-y:overlay;
//     font-size:15px;
// }

// .ant-layout-sider {color: #778495}
.header-search{
    float: left;
    width: 240px;
    margin-right:8px
}
